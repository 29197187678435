import logoW from './nectavida-test-white.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="beeHeader bee-white">
        <header className="App-header">
          <img src={logoW} className="App-logo" alt="logo" />
          <video playsInline autoPlay muted loop poster="/bees.mp4" id="bgvid">
            <source src="/bees.mp4"  type="video/mp4" />
          </video>
        </header>
      </div>
      <div className="moodBoard">
        <section className="row1 row">
          <h1 className="row-items row-content">Hey! This is some text. How is the color?</h1>
          <p className="row-items">
            <img className="row-img" src="/daniel-hopper-2Cz60cDTS6Q-unsplash.jpg" alt="NZ" />
          </p>
        </section>
        <section className="plain-txt row2 row">
          <p>We can have even more text down here about something.  Probably honey, but could be something else.</p>
        </section>
      </div>
      <div className="color-bar">
        <div className="row row3">
          <p className="yellow row-items">Yellow<br />#DC8D2C</p>
          <p className="white row-items">Gray<br />#E3E3E3</p>
          <p className="green row-items">Green<br />#50A271</p>
          <p className="red row-items">Red<br />#885A5A</p>
          <p className="black row-items">Black<br />#353A47</p>
        </div>
      </div>
    </div>
  );
}

export default App;
